import React, {
	useState,
	useEffect,
	useContext
} from "react";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../common/Footer/footer";
import Header from "../common/Header/header";

const Start = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Conditions</title>
            </Helmet>

            <div className="app">
                <Header title="Happy Birthday" subtitle="Esiya &amp; Milaniya" />

                    <main>
                            <div className="error">
                                <p>Такой страницы нету</p>
                            </div>
                    </main>

                    <section className="controls">
                        <Link to="/" className="button secondary">
                            Назад
                        </Link>
                    </section>

                <Footer />
            </div>
        </>
    );
}

export default Start;
