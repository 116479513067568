import React, {
    useState,
    useEffect,
    useContext
} from "react";

import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";
import Button from "../common/Button/Button";
import Footer from "../common/Footer/footer";
import Header from "../common/Header/header";

const Quest5 = () => {

    const [answerBlue, setAnswerBlue] = useState("");
    const [answerOrange, setAnswerOrange] = useState("");
    const [answerBlack, setAnswerBlack] = useState("");

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const handleFieldBlueChange = (e) => {
        setError(false);

        let inputName = e.target.name;
        let inputValue = e.target.value;

        setAnswerBlue(inputValue);
    }

    const handleFieldOrangeChange = (e) => {
        setError(false);

        let inputName = e.target.name;
        let inputValue = e.target.value;

        setAnswerOrange(inputValue);
    }

    const handleFieldBlackChange = (e) => {
        setError(false);

        let inputName = e.target.name;
        let inputValue = e.target.value;

        setAnswerBlack(inputValue);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (answerBlue === "2" &&
            answerBlack === "8" &&
            answerOrange === "13" ) {

            // Success
            setSuccess(true);
        } else {
            // Error
            setError("Попробуй ещё раз");

            // Clear answer
            setAnswerBlue("");
            setAnswerOrange("");
            setAnswerBlack("");
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pool</title>
            </Helmet>

            <div className="app">
                <Header title="Happy Birthday" subtitle="Esiya &amp; Milaniya" />
                {success ?
                    <>
                        <main>
                            <div className="image">
                                <img src="/qr-count.png" className="image" />
                            </div>
                        </main>
                    </>
                    :
                    <>
                        <main>
                            <ReactSVG className="image" src="/pool.svg" />
                        </main>

                        <section>
                            <div className="error">
                                <p>{error}</p>
                            </div>
                        </section>

                        <section className="controls">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="inputs-group">
                                    <input type="tel" name="blue" className="input blue" maxLength="1" value={answerBlue} onChange={handleFieldBlueChange} />
                                    <input type="tel" name="black" className="input black" maxLength="1" value={answerBlack} onChange={handleFieldBlackChange} />
                                    <input type="tel" name="orange" className="input orange" maxLength="2" value={answerOrange} onChange={handleFieldOrangeChange} />
                                </div>

                                <Button title={"Дальше"} onHandleClick={(e) => handleSubmit(e)} />
                            </form>
                        </section>
                    </>
                }

                <Footer />
            </div>
        </>
    );
}

export default Quest5;
