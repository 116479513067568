import React, {
	useState,
	useEffect,
	useContext
} from "react";

import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";
import Button from "../common/Button/Button";
import Footer from "../common/Footer/footer";
import Header from "../common/Header/header";

const Quest6 = () => {

    const [answer, setAnswer] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const handleFieldChange = (e) => {
        setError(false);

        setAnswer(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if( answer === "45" ){
            // Success
            setSuccess(true);
        }else{
            // Error
            setError("Попробуй ещё раз");

            // Clear answer
            setAnswer("");
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Count</title>
            </Helmet>

            <div className="app">
                <Header title="Happy Birthday" subtitle="Esiya &amp; Milaniya" />
                    {success ?
                        <>
                            <main>
                                <div className="image">
                                    <img src="/qr-congrats.png" className="image" />
                                </div>
                            </main>
                        </>
                    :
                        <>
                            <main>
                                <ReactSVG className="image" src="/count.svg" />
                            </main>

                            <section>
                                <div className="error">
                                    <p>{error}</p>
                                </div>
                            </section>

                            <section className="controls">
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <input type="text" className="input" placeholder="Посчитали?" value={answer} onChange={handleFieldChange} />
                                    <Button title={"Дальше"} onHandleClick={(e) => handleSubmit(e)} />
                                </form>
                            </section>
                        </>
                    }

                <Footer />
            </div>
        </>
    );
}

export default Quest6;
