import './footer.scss';

const Footer = () => {
    return (
        <footer>
            <span className="copyright">&copy; Happy Birthday - Esiya &amp; Milaniya 2021</span>
        </footer>
    );
}

export default Footer;
