import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../common/Footer/footer";
import Header from "../common/Header/header";
import { ReactSVG } from "react-svg";

const Congrats = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Happy Birthday</title>
            </Helmet>

            <div className="app">
                {/* <Header /> */}

                <main className="full-screen">
                    <section className="main-part">
                        <h1>
                            <span>Happy Birthday</span><br />
                            <span>Esiya &amp; Milaniya</span>
                        </h1>
                    </section>

                    <div className="image">
                        <img src="/congrats.jpeg" className="image" />
                    </div>
                </main>

                <section className="main-part">
                    <h2>
                        Теперь можно погонять!
                    </h2>
                </section>
            
                <Footer />
            </div>
        </>
    );
}

export default Congrats;
