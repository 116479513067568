const Button = (props) => {
    const handleClick = (e) => {
        props.onHandleClick(e);
    }

    return (
        <button className={"button"} onClick={(e) => handleClick(e)}>{props.title}</button>
    );
}

export default Button;
