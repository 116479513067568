import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.scss';
import reportWebVitals from './reportWebVitals';

import Home from './components/Home/Home';
import Start from './components/Start/Start';
import Quest1 from './components/Quests/Quest1';
import Quest2 from './components/Quests/Quest2';
import Quest3 from './components/Quests/Quest3';
import Quest4 from './components/Quests/Quest4';
import Quest5 from './components/Quests/Quest5';
import Quest6 from './components/Quests/Quest6';

import Congrats from './components/Congrats/Congrats';

import Page404 from './components/404/Page404';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/start" element={<Start />} />
				<Route path="/balloons" element={<Quest1 />} />
				<Route path="/neon" element={<Quest2 />} />
				<Route path="/tiny-numbers" element={<Quest3 />} />
				<Route path="/morse" element={<Quest4 />} />
				<Route path="/pool" element={<Quest5 />} />
				<Route path="/count" element={<Quest6 />} />

				<Route path="/congrats" element={<Congrats />} />

				<Route path="*" element={<Page404 />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
