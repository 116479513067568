import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../common/Footer/footer";
import Header from "../common/Header/header";
import { ReactSVG } from "react-svg";

const Home = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Happy Birthday</title>
            </Helmet>

            <div className="app">
                {/* <Header /> */}

                <main>
                    <section className="main-part">
                        <h1>
                            <span>Happy Birthday</span><br />
                            <span>Esiya &amp; Milaniya</span>
                        </h1>
                    </section>

                    <ReactSVG className="image" src="/cake.svg" />
                </main>

                <section className="main-part">
                    <h2>
                        Поиграем? :)
                    </h2>
                </section>

                <section className="controls">
                    <Link to="/start" className="button">
                        Погнали!
                    </Link>
                </section>
            
                <Footer />
            </div>
        </>
    );
}

export default Home;
