import React, {
	useState,
	useEffect,
	useContext
} from "react";

import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";
import Button from "../common/Button/Button";
import Footer from "../common/Footer/footer";
import Header from "../common/Header/header";

const Quest4 = () => {

    const [answer, setAnswer] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const handleFieldChange = (e) => {
        setError(false);

        setAnswer(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if( answer.toLocaleLowerCase() === "birthday" ){
            // Success
            setSuccess(true);
        }else{
            // Error
            setError("Попробуй ещё раз");

            // Clear answer
            setAnswer("");
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Morse</title>
            </Helmet>

            <div className="app">
                <Header title="Happy Birthday" subtitle="Esiya &amp; Milaniya" />
                    {success ?
                        <>
                            <main>
                                <div className="image">
                                    <img src="/qr-pool.png" className="image" />
                                </div>
                            </main>
                        </>
                    :
                        <>
                            <main>
                                <ReactSVG className="image" src="/sound.svg" />
                            </main>
                            
                            <section>
                                {/* <a target="_blank" href="https://ru.wikipedia.org/wiki/%D0%90%D0%B7%D0%B1%D1%83%D0%BA%D0%B0_%D0%9C%D0%BE%D1%80%D0%B7%D0%B5">Азбука</a> */}
                            </section>

                            <section>
                                <div className="error">
                                    <p>{error}</p>
                                </div>
                            </section>

                            <section className="controls">
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <input type="text" className="input" placeholder="Ч-ч-ч! Что говорят?" value={answer} onChange={handleFieldChange} />
                                    <Button title={"Дальше"} onHandleClick={(e) => handleSubmit(e)} />
                                </form>
                            </section>
                        </>
                    }

                <Footer />
            </div>
        </>
    );
}

export default Quest4;
