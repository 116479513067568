import { Link } from "react-router-dom";

import Nav from "../Nav/Nav";

import './header.scss';

const Header = (props) => {
    return (
        <header>
            <div className="logo">
                <Link to="/">
                    <span>{props.title}</span><br />
                    <span>{props.subtitle}</span>
                </Link>
            </div>

            {/* <Nav /> */}
        </header>
    );
}

export default Header;
